import { H, Section } from "@jfrk/react-heading-levels";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as titleStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import clsx from "clsx";
import React from "react";

import useError404Settings from "../../../hooks/error404";
import { layout } from "../foundation";

export default function NotFoundPage() {
  const { errordescription, errorsummary, errortitle } = useError404Settings();

  return (
    <article className={clsx(layout.component, layout.componentWidthFull)}>
      <div className={clsx(layout.component, layout.componentWidthNarrow)}>
        {
          <>
            <H className={clsx(titleStyles.component)}>{errortitle}</H>
            <Section>
              {errorsummary}
              <HTML>{errordescription}</HTML>
            </Section>
          </>
        }
      </div>
    </article>
  );
}
