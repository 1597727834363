import { useStaticQuery, graphql } from "gatsby";

export default function useError404Settings() {
  return useStaticQuery(graphql`
    query Error404Settings {
      wp {
        acfOptions404 {
          error404 {
            errordescription
            errorsummary
            errortitle
          }
        }
      }
    }
  `).wp.acfOptions404.error404;
}
